<template>
  <div class="create-card-container">
    <template v-for="item in cards">
      <p class="cat-title">{{ item.cat_name }}</p>
      <a-row>
        <a-col v-for="current in item.apps" class="card-container">
          <div class="card create-card" @click="cardClick(current, item)">
            <div class="app-card">
              <img :src="'/PIC/' + current.iconName" style="width: 35px; height: 35px;"/>
              <!--              <img :src="current.icon" style="width: 35px; height: 35px;"/>-->
              <div>
                <span class="title">{{ current.name }}</span>
                <div class="content text-overflow">{{ current.info }}</div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
export default ({
  props: {
    cards: {
      type: Array,
      default: []
    }
  },
  data() {
    return {}
  },
  methods: {
    cardClick(card, catInfo) {
      this.$emit('cardClick', card, catInfo)
    }
  }

})

</script>

<style scoped lang="scss">
.create-card-container {
  padding-right: 15px;

  .cat-title {
    margin: 15px 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .card-container {
    grid-template-rows: 70px 20px;

    .create-card {
      height: 70px;

      .title {
        margin-left: 10px;
      }

      .content {
        height: 20px;
        margin: 7px 0 0 10px;
        -webkit-line-clamp: 1;
        color: #8C8C8C;
      }
    }
  }


  .card-container {
    border-radius: 3px;
  }

}
</style>